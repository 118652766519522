.page-node-514 {
  .team-intro {
    background: #efefef;
    border-radius: 0;
    margin: 0;

    h3 {
      //color: #fff;
    }
  }
}