.navbar {
  border-radius: 0px;
}

.site-header {
  background: $color--primary;
  border: none;
  padding-bottom: 8px;
  margin-bottom: 0;
}

.navbar-header {
  float: none;
  padding-top: 25px;
  padding-bottom: 25px;

  .region.region-header {
    display: none;

    @media(min-width: $screen-sm-min) {
      display: inline-block;
      float: right;
      color: #fff;
    }

    h3 {
      font-weight: 400;
      margin: 0px 0 0;
      font-family: ff-good-headline-web-pro-con,sans-serif;
      line-height: 1.6;
      font-size: 26px;
      color: #fff;
    }
  }
}

.logo {
  display: block;

  @media(min-width: $screen-sm-min) {
    display: inline-block;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

/*==================================
  # Navigation
 ==================================*/

#navbar-collapse {

  nav {
    @media(min-width: $screen-md-min) {
      text-align: center;
    }
  }
  .navbar-nav {
    @media(min-width: $screen-md-min) {
      float: none;
      display: inline-block;
      vertical-align: bottom;
    }
  }

  li {

    &:hover a::after {
      opacity: 1;
      transition: all .2s;
      transform: scale(1,1);
    }

    a {
      transition: all .2s;

      &::after {
        content: '';
        height: 2px;
        background-color: $color--secondary;
        width: 100%;
        display: block;
        opacity: 0;
        transform: scale(0,1);
        transform-origin: left center;
        transition: all .2s;
      }

      &:hover {
        color: inherit;
      }
    }
  }

  .leaf.active,
  .expanded.active {
    position: relative;

    @media(min-width: $screen-md-min) {
      &::after {
        $triangle-size: 7px;

        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: $triangle-size solid transparent;
        border-right: $triangle-size solid transparent;
        border-bottom: $triangle-size solid $color--primary;
      }
      a {
        color: inherit;
        &::after {
          opacity: 1;
          transform: scale(1,1);
        }
      }
    }

    a {
      background: none;
    }
  }
}

/*==================================
  # Mobile Toggle
 ==================================*/
.navbar-toggle__wrap {
  background: #fff;

  @media(min-width: $screen-md-min) {
    display: none;
  }
}

.navbar-toggle__wrap--inner {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-toggle {
  float: none;
  border: none;
  margin-right: 0;

  @media(min-width: $screen-md-min) {
    display: none;
  }

}

.navbar-toggle__text {
  text-transform: uppercase;
}

.navbar-collapse {
  border: none;
  background: #fff;
}


.menu.nav {
  a {
    font-family: $font--secondary;
    font-size: 21px;
    font-weight: normal;
    text-transform: uppercase;
  }
}

.front .region-content {
  display: none;
}