.site-footer {
  background: #012b49;
  color: #fff;
  padding: 40px 0 0px;

  h2 {
    color: #fff;
    font-weight: 100;
    margin-bottom: 15px;
    margin-top: 0;
    font-family: ff-good-headline-web-pro-con,sans-serif;
    font-size: 26px;
    text-transform: uppercase;
  }
  .nav>li>a {
    padding-left: 0px;
    padding-right: 0px;
    color: #D0E2FF;

    &:hover {
      color: darken(#D0E2FF,15%);
      background-color: transparent;
    }
  }

  .footer-bottom {
    background-color: #232323;
    padding: 30px 0;
    margin-top: 30px;
  }


}

.footer .footer__directions-link {
  color: #336FD8;

  &:hover {
    color: #4D89F2;
  }
}

.footer__office-1 {
  margin-bottom: 30px;

  @media(min-width: $screen-sm-min) {
    float: left;
    margin-bottom: 0;
  }
}

.footer__office-2 {
  @media(min-width: $screen-sm-min) {
    float: right;
  }
}

.phone-link--footer {
  color: #fff;

  &:hover {
    color: #ccc;
    text-decoration: none;
  }
}


/*=================================
  # Footer Credits
 =================================*/

.footer-credits__wrap {
  margin-top: 30px;
  border-top: 1px solid #2F455B;
}

.footer-credits {
  padding: 30px 0 0 0;
  line-height: 1.5;
  margin: 0 15px;

  a {
    white-space: nowrap;
    text-decoration: underline;
    color: #fff;
  }
}