.hero {
  background: $color--primary;
  color: #fff;
  
  .block {

    @media(min-width: $screen-sm-min) {
      height: auto;
    }
  }

  img {
    min-height: 300px;
    width: 100%;
    object-fit: cover;
  }

  img.team {
    min-height: 0;
    max-height: 170px;
    width: auto;
    height: auto;
  }
} 


.views-row-3.views_slideshow_slide {
    .views-field-body {
      justify-content: flex-end !important;

      .field-content {
        margin-right: 120px;
      }
    }
}

.views_slideshow_slide {
  .views-field-body {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    align-items: center;
    justify-content: center;

    .field-content {
      text-align: center;
      font-size: 40px;
      font-family: ff-good-headline-web-pro-con,sans-serif;
      text-shadow: 0 0 6px #000000;
      background: rgba(0,0,0,0.6);
      padding-left: 40px;
      padding-right: 40px;;
    }
  }
}

.views-slideshow-controls-bottom {
  position: absolute;
  display: flex !important;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center; 
  z-index: 9999;
  flex-direction: row;
  

  .views-slideshow-simple-pager {
    display: flex;
    flex-direction: row;
  }

  .views_slideshow_pager_field_item a {
    text-indent: -9999px;
    display: block;
    background: transparent;
    border: 2px solid #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    margin: 0px 5px;
  }

  .views_slideshow_pager_field_item+.active a {
       text-indent: -9999px;
      display: block;
      background: #ffffff;
      width: 18px;
      height: 18px;
      border-radius: 9px;
  }

}

.views-slideshow-cycle-main-frame-row {
  width: 100% !important;
}

.views-slideshow-cycle-processed {
  position: relative;
}

