
.container {
  .jumbotron {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 40px;
    background: none;
    margin-bottom: 0;

    p {
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      min-height: 150px;
      object-fit:cover;
    }
  }
}