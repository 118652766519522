$grid-breakpoint--sold: 414px;

.view-properties-sold,
.view-properties-leased {
  table {

    tr {
      border-bottom: none;
    }

    td {
      width: 100%;
      float: left;
      text-align: center;
      border-bottom: 4px solid $table-border-color;
    }
  }

  .field-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media(min-width: $grid-breakpoint--sold) {
      flex-wrap: nowrap;
      justify-content: flex-start;

      .prop-left {
        width: 50%;
        max-width: 220px;

      }

      .prop-right {
        width: calc(50% - 20px);
        @media(min-width: $screen-md-min) {
          width: calc(100% - 240px);
        }
      }
    }
  }

  .prop-left {
    width: 100%;
    margin-bottom: 15px;

    @media(min-width: $grid-breakpoint--sold) {
      width: auto;
    }

    img {
      margin: 0 auto;
      min-width: 130px;
    }
  }

  .prop-right {
    width: 100%;
    text-align: center;
    font-style: italic;

    @media(min-width: $grid-breakpoint--sold) {
      width: auto;
      margin-left: 20px;
      text-align: left;
    }
  }


}

.title {
  font-weight: 800;
}

.street-block,
.addressfield-container-inline,
.title {
  font-style: normal;
}

.property-listing {

  > .views-field {
    padding: 30px 0;
    border-bottom: 4px solid #f1f1f1;

    @media(min-width: $screen-sm-min) {
      border-bottom: none;
    }
  }
}