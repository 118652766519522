.prefix__wrap {
  margin: 40px 0;
}

#block-block-4,
#block-block-6,
#block-block-7 {
  text-align: center;
  padding: 0;
  transition: opacity .2s;

  @media(min-width: $screen-sm-min) {
    padding: 0 15px;
  }

  @media(min-width: $screen-md-min) {
    padding: 0;
  }

  &:hover {
    opacity: .8;
  }

}

#block-block-4,
#block-block-6 {
  margin-bottom: 20px;
}

