.galleria-container,
.geofieldMap {
  max-width: 100%;
}

.galleria-container {
  margin: 0 auto;

  @media(min-width: $screen-sm-min) {
    margin: 0;
  }
}