table {
  width: 100%;

  td {
    padding: 30px;
  }

  tr {
    border-bottom: 1px solid #f1f1f1;
  }
}