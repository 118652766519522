.node {
  margin-bottom: 30px;

  .field {
    margin-bottom: 15px;
  }

  .btn {
    margin-bottom: 20px;
  }
}