.page-header {
  background: $color--primary;
  color: #fff;
  padding: 30px 45px;
  margin-top: 0px;
  border-radius: 0px;
  margin-bottom: 40px;

  h1 {
  	color: #ffffff;
  	font-family: ff-good-headline-web-pro-con,sans-serif;
  	text-transform: uppercase;
  	font-weight: 400;
  	margin-top: 0px;
  	margin-bottom: 0px;
  }
}