$td-breakpoint: 470px;

.view-grouping-header {
  font-size: 30px;
  background: $color--primary;
  color: #fff;
  padding: 15px;
  margin-top: 10px;
}

.view-properties-all,
.view-properties-for-sale {
  table {
    width: 100%;

    img {
      float: right;
    }

    td {
      padding: 30px 0;
      @media(min-width: $td-breakpoint) {
        padding: 30px 0 30px;
      }
    }

    tr {
      border-bottom: 4px solid #f1f1f1;
    }
  }

  .field-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .prop-left {
      order: 2;
      width: 100%;
      text-align: center;

      @media(min-width: $td-breakpoint) {
        width: 50%;
        text-align: left;
        padding-left: 20px;
      }
    }

    .prop-right {
      width: 100%;

      @media(min-width: $td-breakpoint) {
        width: auto;
      }

      img {
        float: none;
        margin: 0 auto 15px;

        @media(min-width: $td-breakpoint) {
          min-width: 220px;
          margin: 0;
        }
      }
    }
  }
}
