body.page-our-team {
  .view-our-team {
    h2 {
    font-family: ff-good-headline-web-pro-con,sans-serif;
    font-weight: 400;
    font-size: 48px;
    border-bottom: 2px solid #E0E0E0;
    margin-top: 0px;
    margin-bottom: 20px;

    span {
      font-size: 22px;
      color: #555555;
    }
  }

    .views-row {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
  }
  .member-info {
    display: block;
    margin-top: 20px;

    p {
      margin-bottom: 5px;
      font-family: ff-good-headline-web-pro-con,sans-serif;
    font-weight: 400;
    color: #666666;
    font-size: 22px;
    }
  }
}