#block-block-11,
#block-block-12 {
  ul {
    list-style: none;
    line-height: 2;
    padding-left: 15px;

    li {
      font-size: 18px;

      ul {
        li {
          font-size: 15px;
        }
      }
    }
  }
}

#block-block-12 {

  ul {
    li {
      font-size: 15px;
      background: #e6e6e6;
      max-width: 215px;
      margin: 0 4px 30px 0;
      float: left;

      @media(min-width: 640px) {
        width: calc(33.3333% - 5px);
      }

      @media(min-width: $screen-md-min) {
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        padding: 0 5px;
        display: block;
      }
    }
  }
}