img {
  max-width: 100%;
  height: auto;
}

a > img {
  transition: opacity .2s;

  &:hover {
    opacity: .85;
  }
}